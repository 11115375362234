import axios from "axios";

export const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:4000";
const axiosService = axios.create({
  baseURL: baseUrl + "/internal-platform",
});
axiosService.interceptors.response.use(
  (response) => {
    const token = response.headers["internal-platform-email"];
    if (token) {
      window.localStorage.setItem("email", token);
    }
    return response;
  },
  (error) => {
    console.log(error.response);
    if (error.response.status === 401) {
      window.localStorage.removeItem("email");
    }
    return Promise.reject(error);
  }
);

axiosService.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("email");
    if (token && config.headers) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosService;
