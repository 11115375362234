import * as React from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Login from "./Login";
import Home from "./Home";

export default function App() {
  const localStorageUser = localStorage.getItem("email");
  const [user, setUser] = React.useState<any>(localStorageUser || null);
  console.log(user);
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        {user ? <Home setUser={setUser} /> : <Login setUser={setUser} />}
      </Box>
    </Container>
  );
}
